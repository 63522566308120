<template>
  <form @submit.prevent="post" class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">Profile</h1>
        <button v-if="!editMode" @click="editProfile" class="center gap-10">
          <s-icon color="white" height="16">pencil-outline</s-icon>
          <span class="font-14 opacity-75 weight-300">Edit profile information</span>
        </button>
        <div class="center gap-10" v-if="editMode">
          <s-btn @click.native="closeProfile"> Close </s-btn>
          <s-btn @click.native="editProfile" class="green"> Save </s-btn>
        </div>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center py-50 gap-20 col">
      <div class="w600 weight-300">Manage your personal information</div>
    </section>
    <form ref="formAvatar" class="none" enctype="multipart/form-data">
      <input type="file" @change="upload" name="avatar" ref="fileAvatar" />
    </form>
    <section class="center col py-20">
      <div class="w600 weight-300">Profile avatar</div>
      <div class="relative">
        <div class="profile">
          <img class="pic" v-if="avatar" :src="avatarUrl" />
          <s-icon height="100" v-if="!avatar">account</s-icon>
          <pulse-loader
            :loading="load"
            color="white"
            size="10px"
            class="absolute"
          ></pulse-loader>
        </div>
        <button
          v-if="editMode"
          @click="openPicker"
          type="button"
          class="white round camera center opacity-60"
        >
          <s-icon class="">camera</s-icon>
        </button>
      </div>
    </section>
    <section class="center py-20 col gap-20">
      <div class="w600 weight-300">Contact Information</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">{{ $t("modal.account.email") }}</div>
        <s-text-field
          :noedit="editMode"
          v-model="email"
          disabled
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">
          {{ $t("modal.account.first_name") }}
        </div>
        <s-text-field
          :noedit="editMode"
          v-model="firstNameLocal"
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">
          {{ $t("modal.account.last_name") }}
        </div>
        <s-text-field
          :noedit="editMode"
          v-model="lastNameLocal"
          height="30"
        ></s-text-field>
      </div>
    </section>

    <section class="center py-20 col gap-20" v-if="editMode">
      <div class="w600 weight-300">Password</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">
          {{ $t("modal.account.current_password") }}
        </div>
        <s-text-field
          :noedit="editMode"
          v-model="currentPassword"
          type="password"
          password
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">
          {{ $t("modal.account.new_password") }}
        </div>
        <div>
          <s-text-field
            :noedit="editMode"
            v-model="password1"
            bar
            password
            height="30"
            type="password"
          ></s-text-field>
        </div>
        <div class="font-14 opacity-60">
          {{ $t("modal.account.confirm_password") }}
        </div>
        <s-text-field
          :noedit="editMode"
          v-model="password2"
          type="password"
          password
          height="30"
        ></s-text-field>
      </div>
    </section>

    <section class="center py-20 col gap-20" v-if="!editMode">
      <div class="w600 weight-300 font-14 opacity-75">
        <button @click="editProfile">
          Edit profile to change your password
        </button>
      </div>
    </section>
  </form>
</template>

<script>
import { mapState } from "vuex";
import { gsap } from "gsap";
export default {
  data() {
    return {
      filtedItems: [],
      search: "",
      admin: "Administrator",
      pickerID: -1,
      load: false,
      firstNameLocal: "",
      lastNameLocal: "",
      currentPassword: "",
      password1: "",
      password2: "",
      editMode: false,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    editProfile() {
      this.editMode = !this.editMode;
      if (!this.editMode) this.post();
    },
    closeProfile() {
      this.firstNameLocal = this.firstName;
      this.lastNameLocal = this.lastName;
      this.editMode = !this.editMode;
    },
    async post() {
      let data = {
        firstName: this.firstNameLocal,
        lastName: this.lastNameLocal,
        oldPassword: this.currentPassword,
        password: this.password1,
      };
      await this.$store.dispatch("user/postUserInformation", data);
      this.$store.dispatch("user/getUserInformation");
    },
    async upload(data) {
      let formData = new FormData(this.$refs.formAvatar);
      if (this.checkLimit(formData)) return;
      if (this.checkFileType(formData)) return;
      this.load = true;
      await this.$store.dispatch("user/setProfilePicture", formData);
      await this.$store.dispatch("user/getUserInformation");
      this.load = false;
    },
    checkLimit(formData) {
      let size = formData.get("avatar").size;
      let limit = 1000000;
      let env = process.env.VUE_APP_LIMIT;
      let mb = (env / limit).toFixed(0);
      if (size < limit) return false;
      this.$store.commit("notifications/push", {
        error: `Image too large, max size: ${mb} MB`,
      });
      return true;
    },
    checkFileType(formData) {
      let ext = formData.get("avatar").type;
      ext = ext.toLowerCase();
      let match = ext.search(/jpg|jpeg|png|gif/);
      if (match >= 0) return false;
      ext = ext.split("/")[1];
      this.$store.commit("notifications/push", {
        error: `File type .${ext} is not supported. Supported file types are as follows: jpg, jpeg, png and gif.`,
      });
      return true;
    },
    openPicker() {
      this.$refs.fileAvatar.click();
    },
  },
  computed: {
    avatarUrl() {
      return process.env.VUE_APP_URL + "/api/avatar/" + this.avatar;
    },
    ...mapState("attendees", ["attendees"]),
    ...mapState("schedule", ["schedule"]),
    ...mapState("user", ["firstName", "lastName", "email", "avatar"]),
  },
  watch: {
    rooms(val) {
      let items = JSON.parse(JSON.stringify(val));
      this.items = items;
      this.filterSearch();
    },
    search(val) {
      let items = JSON.parse(JSON.stringify(this.rooms));
      this.items = items;
      this.filterSearch();
    },
  },
  async mounted() {
    this.$store.dispatch("user/getCountries");
    await this.$store.dispatch("user/getUserInformation");
    this.firstNameLocal = this.firstName;
    this.lastNameLocal = this.lastName;
  },
};
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
  max-width: 600px;
  width: 100%;
}

.profile {
  background-color: grey;
  height: 100px;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.camera {
  position: absolute;
  right: 0px;
  bottom: 4px;
  padding: 2px;
  transition: all 0.2s ease;
}

.camera:hover {
  transform: scale(1.1);
  opacity: 1;
}

.camera:focus {
  background-color: #ffffff;
  transform: scale(1.1);
  opacity: 1;
}

.w600 {
  max-width: 600px;
  width: 100%;
}
</style>